import { AsyncContent } from 'core/ui/async-content/AsyncContent'
import { LoadingState } from 'core/ui/async-content/LoadingState'
import { Container } from 'core/ui/Container'
import { useEffect } from 'react'

import styled from 'styled-components'
import { Link } from 'react-router-dom'

import { Header } from '../Header'
import { useAllTestResults } from '../hooks/useAllTestResults'
import { TestResultCard } from './TestResultCard'
import { TestResultDetails } from './TestResultDetails'
import { Search } from './Search'

const Wrapper = styled.div`
  background: #F5F5F5;
  display: flex;
  flex-direction: row;
  margin-top: 2px;
  width: 100%;
`

const SideNav = styled.aside`
  display: flex;
  flex-direction: column;
  min-width: 150px;
  gap: 4px;
  width: 300px;
`

const CardsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  height: 100%;
`

const Content = styled.div`
  background: white;
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-left: 2px;
  gap: 20px;
  padding: 12px;
`

export function AllTestResultsPage() {
  const { testResults, requestStatus, getTestResultInfo } = useAllTestResults()
  // const [selectedScenario, setSelectedScenario] = useState<Scenario>()

  useEffect(() => {
    const abortController = new AbortController()

    getTestResultInfo({ signal: abortController.signal })

    return () => {
      abortController.abort()
    }
  }, [getTestResultInfo])

  return (
    <Container variant="xxlg">
      <AsyncContent
        status={requestStatus}
        loadingState={<LoadingState />}
        emptyState={<div>Something went wrong</div>}
      >
        <Header />
        <Wrapper>
          <SideNav>
            <Search />
            <CardsList>
              {testResults?.map((value, index) => {
                let id = ++index //TODO API needs to pass ID back
                return (
                  <Link style={{ textDecoration: 'none' }} to={`results/${id}`}>
                    <TestResultCard id={id} testResult={value} />
                  </Link>
                )
              }).reverse()}
            </CardsList>
          </SideNav>
          <Content>
            <TestResultDetails />
          </Content>
        </Wrapper>

        {/* Hidden until we implement the open/close behavior */}
        {/* <ScenarioDetailsPanel scenario={selectedScenario} /> */}
      </AsyncContent>
    </Container>
  )
}
