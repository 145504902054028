import styled from 'styled-components/macro'

import { getToken } from '@loadsmart/loadsmart-ui/dist/theming'

import { type Size, sizes, screen } from './screen'

export type Variant = Size

export type ContainerProps = {
  variant?: Variant
}

export const Container = styled.div<ContainerProps>`
  width: 100%;
  max-width: ${({ variant = 'xxlg' }) => sizes[variant]};
  margin: 0 auto;

  padding: ${getToken('space-s')};

  ${screen.lg} {
    padding: ${getToken('space-none')};
  }
`
