import styled from 'styled-components'

const Wrapper = styled.header`
  align-items: center;
  background: white;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  height: 60px;
  padding-left: 16px;
  width: 100%;
`

const Title = styled.h1`
  color: ${props => props.theme.colors.logo};
  font-size: 24px;
  font-weight: 700;
`

export function Header() {
  return (
    <Wrapper>
      <Title>🌲 evergreen</Title>
    </Wrapper>
  )
}

export default Header
