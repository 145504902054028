export const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT as EnvType

export enum EnvType {
  Local = 'local',
  Qa = 'qa',
  Staging = 'staging',
  Sandbox = 'sandbox',
  Production = 'production',
  Test = 'test',
}

export const envDomain: Record<EnvType, string> = {
  [EnvType.Local]: 'localhost',
  [EnvType.Test]: '.test.loadsmart.com',
  [EnvType.Qa]: '.qa.loadsmart.com',
  [EnvType.Staging]: '.staging.loadsmart.com',
  [EnvType.Sandbox]: '.sandbox.loadsmart.com',
  [EnvType.Production]: '.loadsmart.com',
}

export const __DEV__ = process.env.NODE_ENV === 'development'
export const __TEST__ = process.env.NODE_ENV === 'test'
export const __PROD__ = process.env.NODE_ENV === 'production'

export const ALL_ENV = [
  EnvType.Local,
  EnvType.Qa,
  EnvType.Staging,
  EnvType.Sandbox,
  EnvType.Production,
  EnvType.Test,
]

export const ALL_BUT_PRODUCTION = [
  EnvType.Local,
  EnvType.Qa,
  EnvType.Staging,
  EnvType.Sandbox,
  EnvType.Test,
]

export const API_URL = process.env.REACT_APP_API_URL
