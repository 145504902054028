import React from 'react'
import { RouterProvider } from 'react-router-dom'
import { createGlobalStyle, ThemeProvider } from 'styled-components'
import { router } from './ui/Router'

export const theme = {
  colors: {
    logo: '#1F372D',
    green: '#44AE6F',
    lightGreen: '#D1E5C9', // DEPRECATED
    red: '#D14F4F',
    lightRed: '#F0BFC0', // DEPRECATED
    text: '#666',
    lightText: '#999',
  },
}

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    color: ${_ => theme.colors.text};
    font-family: "Public Sans",sans-serif;
    margin: 0;
    padding: 0;
  }
`

function App() {
  return (
    <ThemeProvider theme={theme}>
      <React.Fragment>
        <GlobalStyle />
        <RouterProvider router={router} />
      </React.Fragment>
    </ThemeProvider>
  )
}

export default App
