import { TestResultRepository } from 'test-results/infra/TestResultRepository'

import { API_URL } from 'core/infra/environment'
import { HttpClient, HttpClientConfig } from 'core/infra/http/HttpClient'

type getResultInformationFactoryDeps = {
  repository: TestResultRepository
}

export function getTestResultFactory({ repository }: getResultInformationFactoryDeps) {
  return async function getTestResult(id: string, options?: HttpClientConfig) {
    try {
      return repository.getTestResult(id, options)
    } catch (error) {
      // log error on Sentry
      throw new Error('Failed to fetch test results', { cause: error })
    }
  }
}

export function getTestResult(id: string, options?: HttpClientConfig) {
  const httpClient = new HttpClient(API_URL)
  const repository = new TestResultRepository({ httpClient })
  const get = getTestResultFactory({ repository })

  return get(id, options)
}
