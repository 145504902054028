import { RequestStatus } from 'core/domain/Request'

type AsyncContentProps = {
  status: RequestStatus
  count?: number
  loadingState: React.ReactNode
  errorState?: React.ReactNode
  emptyState?: React.ReactNode
  children: React.ReactNode
}

export function AsyncContent({
  status,
  count,
  loadingState,
  errorState,
  emptyState,
  children,
}: AsyncContentProps) {
  if (status === RequestStatus.Initial || status === RequestStatus.Pending) {
    return <>{loadingState}</>
  }

  if (status === RequestStatus.Error) {
    return errorState ? <>{errorState}</> : null
  }

  if (status === RequestStatus.Done && count === 0) {
    return emptyState ? <>{emptyState}</> : null
  }

  return <>{children}</>
}
