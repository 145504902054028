import { StatusIcon } from 'icons/StatusIcon'
import styled from 'styled-components'
import { Scenario as ScenarioEntity } from 'test-results/domain/Scenario'
import { StatusChip } from '../StatusChip'

const Wrapper = styled.a`
  border: 1px solid #EEE;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 8px;

  &:hover {
    border: 1px solid #CCC;
  }
`

const TitleWrapper = styled.div`
  align-items: center;
  display: flex;
`

const Title = styled.h3`
  font-size: 14px;
  font-weight: 400;
  margin-left: 8px;
`


type ScenarioProps = {
  scenario: ScenarioEntity
}

export function Scenario({ scenario }: ScenarioProps) {
  return (
    <Wrapper>
      <TitleWrapper>
        {scenario.status && <StatusIcon status={scenario.status} />}
        <Title>{scenario.name}</Title>
      </TitleWrapper>

      {scenario.status && <StatusChip status={scenario.status} />}
    </Wrapper>
  )
}
