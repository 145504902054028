import axios, {
  type AxiosError,
  type AxiosInstance,
  type AxiosRequestConfig,
  type AxiosResponse,
} from 'axios'

export type HttpClientConfig = AxiosRequestConfig
export type HttpClientResponse<T> = AxiosResponse<T>
export type HttpClientError<T> = AxiosError<T>

export interface HttpClientInterface {
  get<T = any>(url: string, config?: HttpClientConfig): Promise<HttpClientResponse<T>>
  post<T = any>(url: string, data?: any, config?: HttpClientConfig): Promise<HttpClientResponse<T>>
  put<T = any>(url: string, data?: any, config?: HttpClientConfig): Promise<HttpClientResponse<T>>
  patch<T = any>(url: string, data?: any, config?: HttpClientConfig): Promise<HttpClientResponse<T>>
  delete<T = any>(url: string, config?: HttpClientConfig): Promise<HttpClientResponse<T>>
}

export type RequestInterceptor = (
  config: AxiosRequestConfig
) => AxiosRequestConfig | Promise<AxiosRequestConfig>
export type ResponseInterceptor = (config: AxiosResponse) => AxiosResponse | Promise<AxiosResponse>

export class HttpClient implements HttpClientInterface {
  private client: AxiosInstance
  static requestInterceptor: RequestInterceptor
  static responseInterceptor: ResponseInterceptor

  constructor(baseURL?: string) {
    this.client = axios.create({
      baseURL,
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json',
      },
    })

    if (typeof HttpClient.requestInterceptor === 'function') {
      this.client.interceptors.request.use(HttpClient.requestInterceptor)
    }

    if (typeof HttpClient.responseInterceptor === 'function') {
      this.client.interceptors.response.use(HttpClient.responseInterceptor)
    }
  }

  static setup(
    interceptors: { request?: RequestInterceptor; response?: ResponseInterceptor } = {}
  ) {
    if (interceptors.request) {
      HttpClient.requestInterceptor = interceptors.request
    }
    if (interceptors.response) {
      HttpClient.responseInterceptor = interceptors.response
    }
  }

  get<T = any>(url: string, config?: HttpClientConfig): Promise<HttpClientResponse<T>> {
    return this.client.get<T>(url, config)
  }

  post<T = any>(
    url: string,
    data?: any,
    config?: HttpClientConfig
  ): Promise<HttpClientResponse<T>> {
    return this.client.post<T>(url, data, config)
  }

  put<T = any>(url: string, data?: any, config?: HttpClientConfig): Promise<HttpClientResponse<T>> {
    return this.client.put<T>(url, data, config)
  }

  patch<T = any>(
    url: string,
    data?: any,
    config?: HttpClientConfig
  ): Promise<HttpClientResponse<T>> {
    return this.client.patch<T>(url, data, config)
  }

  delete<T = any>(url: string, config?: HttpClientConfig): Promise<HttpClientResponse<T>> {
    return this.client.delete<T>(url, config)
  }
}

export function createTokenInterceptor() {
  return function tokenInterceptor(config: HttpClientConfig) {
    let token

    token = process.env.REACT_APP_AUTH_TOKEN

    if (token && config?.headers) {
      config.headers['Authorization'] = token
    }

    return config
  }
}
