import { Feature } from 'test-results/domain/Feature'
import { Scenario } from 'test-results/domain/Scenario'
import { Status } from 'test-results/domain/Status'
import { Step } from 'test-results/domain/Step'
import { TestResult } from 'test-results/domain/TestResult'

export type RawTestResult = {
  environment: string
  stage: string
  features: Array<RawFeature>
  service_versions: Array<RawServiceVersion>
}

export type RawFeature = {
  description: Array<string>
  elements: Array<RawElement>
}

export type RawElement = {
  name: string
  tags: Array<string>
  type: string
  steps: Array<RawStep>
  status: string
  location: string
}

export type RawStep = {
  name: string
  result: RawStepResult
  keyword: string
}

export type RawStepResult = {
  status: string
  duration: number
}

export type RawServiceVersion = {
  name: string
  version: string
}

export function mapApiToTestResult(rawTestResults: RawTestResult): TestResult {
  if (!rawTestResults) throw new Error('rawTestResults is required')

  return {
    environment: rawTestResults.environment,
    stage: rawTestResults.stage,
    features: rawTestResults.features.map(rawFeature => mapApiToFeature(rawFeature)),
  }
}

function mapApiToFeature(rawFeature: RawFeature): Feature {
  if (!rawFeature) throw new Error('rawFeature is required')

  return {
    description: rawFeature.description,
    scenarios: rawFeature.elements.map(e => mapApiToScenario(e)),
  }
}

function mapApiToScenario(rawElement: RawElement): Scenario {
  if (!rawElement) throw new Error('rawElement (a.k.a. scenario) is required')

  return {
    name: rawElement.name,
    tags: rawElement.tags,
    status: mapApiToStatus(rawElement.status),
    location: rawElement.location,
    steps: rawElement.steps.map(s => mapApiToStep(s)),
  }
}

function mapApiToStep(rawStep: RawStep): Step {
  if (!rawStep) throw new Error('rawStep is required')

  return {
    name: rawStep.name,
    keyword: rawStep.keyword,
    status: rawStep.result?.status,
    duration: rawStep.result?.duration,
  }
}

function mapApiToStatus(rawStatus: string): Status | undefined {
  switch (rawStatus.toLowerCase()) {
    case "passed": return Status.Passed
    case "failed": return Status.Failed
    default: return undefined
  }
}