import styled from 'styled-components/macro'

import { LoadingBar, Text } from '@loadsmart/loadsmart-ui'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  max-width: 384px;
  height: 100%;
  width: 100%;
`

export function LoadingState() {
  return (
    <Wrapper>
      <LoadingBar alignment="center" title={<Text>Loading</Text>} />
    </Wrapper>
  )
}
