import styled from "styled-components"

const Wrapper = styled.div`
  background: white;
  display: flex;
  align-items: center;
  height: 110px;
  width: 100%;
`

const SearchInput = styled.input`
  border: 1px solid #EEE;
  height: 27px;
  margin: 0 16px;
  padding: 0px 8px;
  width: 100%;

  ::placeholder {
    color: #CCC;
    opacity: 1;
  }
`

export function Search() {
  return (
    <Wrapper>
      <SearchInput placeholder="search all projects" />
    </Wrapper>
  )
}