import { createBrowserRouter, createRoutesFromElements, Route } from 'react-router-dom'
import { AllTestResultsPage } from 'test-results/ui/root-page/AllTestResultsPage'

export const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route index element={<AllTestResultsPage />} />
    </>
  )
)
