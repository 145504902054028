import { RequestStatus } from 'core/domain/Request'
import { HttpClientError } from 'core/infra/http/HttpClient'
import { useCallback, useState } from 'react'
import { TestResult } from 'test-results/domain/TestResult'
import { getAllTestResults } from 'test-results/use-cases/getAllTestResultsInformation'

type TestResultState = {
  testResults?: Array<TestResult>
  requestStatus: RequestStatus
}

const initialTestResultState: TestResultState = {
  requestStatus: RequestStatus.Initial,
}

export function useAllTestResults() {
  const [state, setState] = useState<TestResultState>(initialTestResultState)

  const getTestResultInfo = useCallback(async function getTestResultInfo(
    options: { signal?: AbortSignal } = {}
  ) {
    setState({ requestStatus: RequestStatus.Pending })
    try {
      const allTestResults = await getAllTestResults(options)
      setState({ testResults: allTestResults, requestStatus: RequestStatus.Done })
    } catch (e) {
      if (!options.signal?.aborted) {
        const error = e as HttpClientError<unknown>
        console.error(error.message)
        setState({ requestStatus: RequestStatus.Error })
      }
    }
  },
  [])

  return {
    ...state,
    getTestResultInfo,
  }
}
