const IconJournal = ({ ...props }) => (
  <svg viewBox="0 0 16 16" fill="none" {...props}>
    {props.title === undefined ? <title>{'Journal'}</title> : props.title ? <title>{props.title}</title> : null}
    <g fill="currentColor">
      <path d="M6 8V1H7V7.117L8.743 6.07C8.82065 6.02347 8.90948 5.9989 9 5.9989C9.09053 5.9989 9.17935 6.02347 9.257 6.07L11 7.117V1H12V8C12 8.08847 11.9766 8.17535 11.932 8.25179C11.8875 8.32823 11.8235 8.39149 11.7465 8.43511C11.6696 8.47873 11.5824 8.50114 11.4939 8.50007C11.4055 8.499 11.3189 8.47447 11.243 8.429L9 7.083L6.757 8.43C6.68104 8.47552 6.59436 8.50004 6.50582 8.50107C6.41727 8.5021 6.33004 8.4796 6.25304 8.43586C6.17605 8.39213 6.11205 8.32873 6.06759 8.25215C6.02313 8.17557 5.99981 8.08855 6 8V8Z" />
      <path d="M3 0H13C13.5304 0 14.0391 0.210714 14.4142 0.585786C14.7893 0.960859 15 1.46957 15 2V14C15 14.5304 14.7893 15.0391 14.4142 15.4142C14.0391 15.7893 13.5304 16 13 16H3C2.46957 16 1.96086 15.7893 1.58579 15.4142C1.21071 15.0391 1 14.5304 1 14V13H2V14C2 14.2652 2.10536 14.5196 2.29289 14.7071C2.48043 14.8946 2.73478 15 3 15H13C13.2652 15 13.5196 14.8946 13.7071 14.7071C13.8946 14.5196 14 14.2652 14 14V2C14 1.73478 13.8946 1.48043 13.7071 1.29289C13.5196 1.10536 13.2652 1 13 1H3C2.73478 1 2.48043 1.10536 2.29289 1.29289C2.10536 1.48043 2 1.73478 2 2V3H1V2C1 1.46957 1.21071 0.960859 1.58579 0.585786C1.96086 0.210714 2.46957 0 3 0V0Z" />
      <path d="M1 5V4.5C1 4.36739 1.05268 4.24021 1.14645 4.14645C1.24021 4.05268 1.36739 4 1.5 4C1.63261 4 1.75979 4.05268 1.85355 4.14645C1.94732 4.24021 2 4.36739 2 4.5V5H2.5C2.63261 5 2.75979 5.05268 2.85355 5.14645C2.94732 5.24021 3 5.36739 3 5.5C3 5.63261 2.94732 5.75979 2.85355 5.85355C2.75979 5.94732 2.63261 6 2.5 6H0.5C0.367392 6 0.240215 5.94732 0.146447 5.85355C0.0526784 5.75979 0 5.63261 0 5.5C0 5.36739 0.0526784 5.24021 0.146447 5.14645C0.240215 5.05268 0.367392 5 0.5 5H1ZM1 8V7.5C1 7.36739 1.05268 7.24021 1.14645 7.14645C1.24021 7.05268 1.36739 7 1.5 7C1.63261 7 1.75979 7.05268 1.85355 7.14645C1.94732 7.24021 2 7.36739 2 7.5V8H2.5C2.63261 8 2.75979 8.05268 2.85355 8.14645C2.94732 8.24021 3 8.36739 3 8.5C3 8.63261 2.94732 8.75979 2.85355 8.85355C2.75979 8.94732 2.63261 9 2.5 9H0.5C0.367392 9 0.240215 8.94732 0.146447 8.85355C0.0526784 8.75979 0 8.63261 0 8.5C0 8.36739 0.0526784 8.24021 0.146447 8.14645C0.240215 8.05268 0.367392 8 0.5 8H1ZM1 11V10.5C1 10.3674 1.05268 10.2402 1.14645 10.1464C1.24021 10.0527 1.36739 10 1.5 10C1.63261 10 1.75979 10.0527 1.85355 10.1464C1.94732 10.2402 2 10.3674 2 10.5V11H2.5C2.63261 11 2.75979 11.0527 2.85355 11.1464C2.94732 11.2402 3 11.3674 3 11.5C3 11.6326 2.94732 11.7598 2.85355 11.8536C2.75979 11.9473 2.63261 12 2.5 12H0.5C0.367392 12 0.240215 11.9473 0.146447 11.8536C0.0526784 11.7598 0 11.6326 0 11.5C0 11.3674 0.0526784 11.2402 0.146447 11.1464C0.240215 11.0527 0.367392 11 0.5 11H1Z" />
    </g>
  </svg>
)

export default IconJournal