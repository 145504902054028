export const enum RequestStatus {
  Initial = 'initial',
  Pending = 'pending',
  Done = 'done',
  Error = 'error',
}

export type RequestListResult<T> = {
  count: number
  next: string | null
  previous: string | null
  data: Array<T>
}

export type RequestResult<T> = {
  data: T
}

export type ServerError = {
  error: string
  error_description: string
  errors: {
    [key: string]: Array<string>
  }
}
