import { colorForStatus, textForStatus } from "core/ui/utils";
import styled from "styled-components";
import { Status } from "test-results/domain/Status";

type StatusChipProps = {
  status: Status
}

export const StatusChip = styled.span<StatusChipProps>`
  background: ${props => colorForStatus(props.status)};
  border-radius: 10px;
  color: white;
  display: block;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  min-width: 60px;
  max-height: 20px;
  padding: 0 10px;
  text-align: center;

  ::after {
    content: '${props => textForStatus(props.status)}';
  }
`
