import { theme } from "core/App";
import { Status } from "test-results/domain/Status";

export function colorForStatus(status: Status) {
  switch (status) {
    case Status.Passed: return theme.colors.green;
    case Status.Failed: return theme.colors.red;
  }
}

export function textForStatus(status: Status) {
  switch (status) {
    case Status.Passed: return "Passed";
    case Status.Failed: return "Failed";
  }
}