export type Size = 'sm' | 'md' | 'lg' | 'xlg' | 'xxlg'

export const intSizes: Record<Size, number> = {
  sm: 320,
  md: 768,
  lg: 1024,
  xlg: 1280,
  xxlg: 1440,
}

const keys = Object.keys(intSizes) as Size[]

export const sizes = keys.reduce(
  (result, sizeName) => ({
    ...result,
    [sizeName]: `${intSizes[sizeName]}px`,
  }),
  {} as Record<Size, string>
)

export const screen = keys.reduce(
  (result, sizeName) => ({
    ...result,
    [sizeName]: `@media (min-width: ${sizes[sizeName]})`,
  }),
  {} as Record<Size, string>
)
