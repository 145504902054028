import IconBox from 'icons/IconBox'
import styled from 'styled-components'
import { Feature as FeatureEntity } from 'test-results/domain/Feature'
import { Scenario } from './Scenario'

const TitleWrapper = styled.div`
  align-items: center;
  display: flex;
  gap: 8px;
`

const Title = styled.h2`
  font-size: 18px;
  font-weight: normal;
`

const ScenariosWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-top: 16px;
`

type FeatureProps = {
  feature: FeatureEntity
}

export function Feature({ feature }: FeatureProps) {
  return (
    <div>
      <TitleWrapper>
        <IconBox title="Feature" width="16" height="16" />
        <Title>{feature.description[0]}</Title>
      </TitleWrapper>
      {/* {feature.description.length > 1 && <Message>{feature.description[1]}</Message>} */}

      <ScenariosWrapper>
        {feature.scenarios.map(scenario => (
          <Scenario scenario={scenario} />
        ))}
      </ScenariosWrapper>
    </div>
  )
}
