import styled from 'styled-components'

import { statusForTestResult, TestResult } from "test-results/domain/TestResult"
import { Status } from 'test-results/domain/Status'
import { StatusIcon } from 'icons/StatusIcon'

type TestResultProps = {
  testResult: TestResult
  id: number
}

function colorMap(status: Status, theme: any) {
  if (status === undefined) {
    return "white"
  }

  return {
    "passed": theme.colors.green,
    "failed": theme.colors.red
  }[status]
}

interface CardProps {
  status: Status
}

const Card = styled.div<CardProps>`
  background-color: white;
  border-left: 4px solid ${props => colorMap(props.status, props.theme)};
  display: flex;
  flex-direction: row;
  height: 50px;
  justify-content: space-between;
  padding: 16px;
  text-decoration: none;
  width: 100%;
`

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`

const ProjectName = styled.span<CardProps>`
  color: ${props => colorMap(props.status, props.theme)};
  flex: 1;
  font-size: 16px;
  margin-left: 8px;
`

const TestResultID = styled.span<CardProps>`
  color: ${props => colorMap(props.status, props.theme)};
`

export function TestResultCard({ id, testResult }: TestResultProps) {

  const status = statusForTestResult(testResult)

  return (
    <Card status={status}>
      <TitleWrapper>
        <StatusIcon status={status} />
        {/* Displaying 'environment' now but later we could show project name */}
        <ProjectName status={status}>{testResult.environment}</ProjectName>
        <TestResultID status={status}>#{id}</TestResultID>
      </TitleWrapper>
      {/* Add other metadata here later */}
    </Card>
  )
}
