import { colorForStatus } from "core/ui/utils"
import IconJournal from "icons/IconJournal"
import { StatusIcon } from "icons/StatusIcon"
import { useEffect } from "react"
import { useParams } from "react-router-dom"
import styled from "styled-components"
import { Status } from "test-results/domain/Status"
import { statusForTestResult } from "test-results/domain/TestResult"
import { useTestResult } from "../hooks/useTestResult"
import { Feature as FeatureComponent } from "./details/Feature"

type TestResultDetailsProps = {
  id: string
}

const Wrapper = styled.div`
  background: white;
  display: flex;
  flex-direction: column;
  padding: 42px 16px;
`

const TitleWrapper = styled.div`
  display: flex;
  align-items: baseline;
`

const Title = styled.h1`
  font-size: 32px;
  font-weight: 400;
  margin-left: 12px;
`

const When = styled.p`
  color: ${props => props.theme.colors.lightText};
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  margin-left: 32px;
`

type SummaryProps = {
  status: Status
}

const Summary = styled.div<SummaryProps>`
  border-left: 4px solid ${props => colorForStatus(props.status)};
  border-right: 1px solid #EEE;
  border-top: 1px solid #EEE;
  border-bottom: 1px solid #EEE;
  display: flex;
  font-size: 14px;
  flex-direction: column;
  height: 160px;
  justify-content: space-between;
  margin-top: 28px;
  padding: 14px;
  width: 100%;
`

const FeaturesList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 48px;
  margin-top: 42px;
`

export function TestResultDetails() {
  const { id } = useParams<TestResultDetailsProps>()
  console.log(id)

  const { testResult, /*requestStatus, */getTestResultInfo } = useTestResult()

  let status: Status | undefined = undefined
  if (testResult) {
    status = statusForTestResult(testResult)
  }

  useEffect(() => {
    // if (!id) return

    const abortController = new AbortController()
    // getTestResultInfo(id, { signal: abortController.signal })
    getTestResultInfo("1", { signal: abortController.signal })

    return () => {
      abortController.abort()
    }
  }, [getTestResultInfo])

  return (
    <Wrapper>
      <TitleWrapper>
        <IconJournal title="Project" height="20" width="20" />
        <Title>environment: {testResult?.environment}</Title>
      </TitleWrapper>
      <When>(_ sec ago)</When>

      {status && (<Summary status={status}>
        {/* maybe use StatusChip */}
        <p>
          <StatusIcon status={status} /> {status === Status.Passed ? 'Passed' : 'Failed'}
        </p>

        {/* TODO: waiting on backend to return digested data */}
        <div>
          <p><b>Features:</b> passed 4, failed 0</p>
          <p><b>Scenarios:</b> passed 4, failed 0</p>
          <p><b>Steps:</b> passed 4, failed 0</p>
        </div>

        <p><i>Finished in 2 min 32 sec</i></p>
      </Summary>)}

      <FeaturesList>
        {testResult?.features.map((feature, index) => (
          <FeatureComponent feature={feature} key={index} />
        ))}
      </FeaturesList>
    </Wrapper>
  )
}