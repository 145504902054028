import { Status } from "test-results/domain/Status";
import IconCheck from "./IconCheck";
import IconX from "./IconX";

type StatusIconProps = {
  status: Status
}

export function StatusIcon({ status }: StatusIconProps) {
  const size = { width: 20, height: 20 }
  switch (status) {
    case Status.Failed: return <IconX {...size} />
    case Status.Passed: return <IconCheck {...size} />
  }
}