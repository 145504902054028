import { RequestResult } from 'core/domain/Request'
import { HttpClient, HttpClientConfig } from 'core/infra/http/HttpClient'

import { mapApiToTestResult, RawTestResult } from './testResultMapper'

type TestResultRepositoryDeps = {
  httpClient: HttpClient
}

export class TestResultRepository {
  httpClient: HttpClient

  constructor({ httpClient }: TestResultRepositoryDeps) {
    this.httpClient = httpClient
  }

  async getAllTestResults(config?: HttpClientConfig) {
    const endpoint = '/results/results/'

    const { data } = await this.httpClient.get<RequestResult<[RawTestResult]>>(endpoint, config)
    const rawData = data as unknown as [RawTestResult]

    try {
      const allTestResults = rawData.map(rawTestResult => mapApiToTestResult(rawTestResult))
      return allTestResults
    } catch (e) {
      throw new Error('Failed to parse Test Results', { cause: e })
    }
  }

  async getTestResult(id: string, config?: HttpClientConfig) {
    const endpoint = `/results/results/${id}`

    const { data } = await this.httpClient.get<RequestResult<RawTestResult>>(endpoint, config)
    const rawData = data as unknown as RawTestResult

    try {
      const testResult = mapApiToTestResult(rawData)
      return testResult
    } catch (e) {
      throw new Error('Failed to parse Test Result', { cause: e })
    }
  }
}
