import { Feature } from './Feature'
import { Status } from './Status'

export type TestResult = {
  environment: string
  stage: string
  features: Array<Feature>
}

export function statusForTestResult(testResult: TestResult): Status {
  return testResult.features
    .flatMap(result => result.scenarios)
    .some(scenario => scenario.status === Status.Failed) ? Status.Failed : Status.Passed
}